




















































import Vue from 'vue';
import StaffDrawer from '../staff/Drawer.vue';
import StaffAppBar from '../staff/AppBar.vue';
import LayoutView from '../staff/View.vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  components: {
    StaffDrawer,
    StaffAppBar,
    LayoutView,
  },
  data() {
    return {
      drawerAction: false,
      dialog: false,
    };
  },
  computed: {
    userID(): number {
      const userInfo = JSON.parse(localStorage.getItem('user')!);
      const userId = userInfo == null ? null : userInfo.pk;
      return userId;
    },
  },
  methods: {
    ...mapActions({
      fetchAccount: 'account/fetchAccount',
    }),

    drawerChange() {
      (this as any).$refs.drawerControl.open(true);

      this.drawerAction = !this.drawerAction;
      return this.drawerAction;
    },
  },
  async mounted() {
    const user = await this.fetchAccount(this.userID);
    if (user.role == 'C') {
      this.dialog = true;
    }
  },
});
